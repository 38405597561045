import { Injectable } from '@angular/core';
import { LocalStorageService } from '@services/admin/localstorage/local.service';
import { LocalStorageMerchantService } from '@services/merchant/localstorage/local.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
		private localStorageService: LocalStorageService,private localStorageMerchantService: LocalStorageMerchantService) { }
  isLoggedIn() {
    let userProfile:any = this.localStorageService.getUserProfile();
    const session = userProfile?.token;
    if (session) {
      return true;
    } else {
      return false;
    }
  }
  isLoggdInMerchant(){
      let userProfile:any = this.localStorageMerchantService.getUserProfile();
      const session = userProfile?.token;
      if (session) {
        return true;
      } else {
        return false;
      }
  }
}
