import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  apiUrl:string = environment.backendHost;
  projectName: string = environment.project;
  constructor(private http :HttpClient) {
    
  }

  sendUserProfile(data:any){
    localStorage.setItem(
        this.projectName+'userProfile',
        JSON.stringify(data)
    );
  }
  getUserProfile(){
   let userProfile:any = localStorage.getItem(this.projectName+'userProfile')  || null;
   userProfile = JSON.parse(userProfile);
   return userProfile;
  }
  removeUerProfile(){
    localStorage.removeItem(this.projectName+'userProfile');
  }
  sendpinCode(data:any){
    localStorage.setItem(this.projectName+'userPincode',JSON.stringify(data));
  }
  getPinCode(){
    let userPincode:any = localStorage.getItem(this.projectName+'userPincode')  || null;
    userPincode = JSON.parse(userPincode);
    return userPincode;
  }
  removePindCode(){
    localStorage.removeItem(this.projectName+'userPincode');
  }
  sendAdminPermisson(data:any){
    localStorage.setItem(this.projectName+'adminPermisson',JSON.stringify(data));
  }
  getAdminPermisson(){
    let adminPermisson:any = localStorage.getItem(this.projectName+'adminPermisson')  || null ;
    adminPermisson = JSON.parse(adminPermisson);
    return adminPermisson;
  
  }
  removeAdminPermisson(){
    localStorage.removeItem(this.projectName+'adminPermisson');
  }
  sendThemeMode(data:any){
    localStorage.setItem(this.projectName+'darkMode',JSON.stringify(data));
  }
  getThemeMode(){
    let darkMode:any = localStorage.getItem(this.projectName+'darkMode')  || null;
    darkMode = JSON.parse(darkMode);
    return darkMode;
  }
  removeThemeMode(data:any){
    localStorage.removeItem(this.projectName+'darkMode');
  }
  sendThemeType(data:any){
    localStorage.setItem(this.projectName+'themeMode',JSON.stringify(data));
  }
  getThemeType(){
    let themeMode:any = localStorage.getItem(this.projectName+'themeMode')  || null;
    themeMode = JSON.parse(themeMode);
    return themeMode;
  }
  removeThemeType(data:any){
    localStorage.removeItem(this.projectName+'themeMode');
  }
}
