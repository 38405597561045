<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link
  href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
  rel="stylesheet"
/>
<link
  rel="stylesheet"
  type="text/css"
  href="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick.css"
/>
<link
  rel="stylesheet"
  type="text/css"
  href="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick-theme.css"
/>

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
  integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
/>

<div class="home-page">
  <div id="darkModeToggleBtn">
    <span><i class="fa-regular fa-moon"></i></span>
  </div>
  <nav class="home-nav" [ngClass]="activeClass" id="nav">
    <div class="top"></div>
    <div class="container">
      <div class="home-header" [class.show]="isClassAdded">
        <div class="nav-menu">
          <ul>
            <li class="menu-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeId === 'about' }"
                href="#about"
                >About</a
              >
            </li>
            <li class="menu-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeId === 'Key_Features' }"
                href="#Key_Features"
                >Key Features</a
              >
            </li>
            <li class="menu-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeId === 'Service' }"
                href="#Service"
                >{{ "service" | translate }}
              </a>
            </li>
            <li class="menu-item">
              <a
                class="nav-link"
                [ngClass]="{ active: activeId === 'Our_Approach' }"
                href="#Our_Approach"
                >Our Approach</a
              >
            </li>
            <!-- <li class="nav-menu-item">
								<a
									routerLink="/documentation-v2"
									routerLinkActive="active"
									>Developers</a
								>
							</li> -->
            <!-- <li class="nav-menu-item dropdown">
								<a
									>Join Us</a
								>
								<ul class="sub-nav">
									<li class="sub-nav-item"><a routerLink="/become-a-partner">{{ 'become_partner' | translate }}</a></li>
									<li class="sub-nav-item"><a routerLink="/become-a-agent">{{ 'become_agent' | translate }}</a></li>
								</ul>
							</li> -->
          </ul>
        </div>

        <div class="nav_logo">
          <a href="/">
            <img class="logo-img" src="assets/img/logo.png" alt="" />
          </a>
        </div>
        <div class="contact">
          <div class="login-btn">
            <a href="#become_a_partner">Contact Us</a>
          </div>
        </div>
        <div class="ham_menu" (click)="showMenu()" [class.show]="isClassAdded">
          <i class="fa-solid fa-bars"></i>
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>
  </nav>
  <section class="home-banner">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="banner-content">
            <h1>
              Instant {{ "payment_solutions" | translate }} Customized to Meet
              Your Business Requirements
            </h1>
            <p>
              Optimize Transactions, Simplify Payouts, and Effectively Handle
              Your Financial Operations Online with Pay2local's Dynamic APIs and
              {{ "merchant_dashboard" | translate }}.
            </p>
            <div class="hm_btn">
              <a href="">Learn More</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="image-wrapper" data-aos="zoom-in-up">
            <img
              class="one"
              src="assets/home/images/image-1.png"
              alt=""
              data-aos="zoom-in-right"
              data-aos-delay="1200"
            />
            <img
              class="two"
              src="assets/home/images/image-2.png"
              alt=""
              data-aos="zoom-in-left"
              data-aos-delay="1200"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about" class="about_us">
    <div class="container">
      <div class="content">
        <p class="title">About Us</p>
        <h2>
          Experience the difference firsthand by choosing us for all your
          payment service needs!
        </h2>
        <p class="description">
          Pay2Local is a payment service provider that specializes in the
          various reliable methods of handling online payments from businesses
          all over the globe.
        </p>
        <p class="description">
          We have covered different types of payments and currencies to provide
          customers with ease-at-no-cost experience.
        </p>
      </div>

      <div class="data" #counterSection>
        <div class="inner">
          <h3>
            <span *ngFor="let digit of agentsDigits" class="odometer-digit">{{
              digit
            }}</span
            >+
          </h3>
          <p>{{ "agents" | translate }}</p>
        </div>
        <div class="inner">
          <h3>
            <span
              *ngFor="let digit of merchantsDigits"
              class="odometer-digit"
              >{{ digit }}</span
            >+
          </h3>
          <p>{{ "merchants" | translate }}</p>
        </div>
        <div class="inner">
          <h3>
            <span
              *ngFor="let digit of transactionsDigits"
              class="odometer-digit"
              >{{ digit }}</span
            >+
          </h3>
          <p>{{ "transactions" | translate }}</p>
        </div>
        <div class="inner">
          <h3>
            <span
              *ngFor="let digit of successRateDigits"
              class="odometer-digit"
              >{{ digit }}</span
            >%
          </h3>
          <p>{{ "success" | translate }}</p>
        </div>
      </div>
    </div>
  </section>

  <section id="Key_Features" class="advatages">
    <div class="container">
      <div class="head-content">
        <h2>Key Features</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="content-wrapper" data-aos="fade-up">
            <i class="fa-regular fa-thumbs-up"></i>
            <h3>Easy Payment Form</h3>
            <p>
              The payment form is extremely user-friendly, requiring minimal
              information.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="content-wrapper" data-aos="fade-up" data-aos-delay="100">
            <i class="fa-regular fa-hourglass"></i>
            <h3>24/7 Support & service</h3>
            <p>
              We operate 24/7 and promptly resolve any issues that may arise.
              Your satisfaction as a client is highly valued by us.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="content-wrapper" data-aos="fade-up" data-aos-delay="200">
            <i class="fa-solid fa-gears"></i>
            <h3>Effortless Integrate</h3>
            <p>It’s very effortless to integrate Pay2local.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="Service" class="payment-options">
    <div class="container">
      <div class="head">
        <p class="title">Services</p>
        <h2>Increase Your Revenue and Accelerate Business Growth</h2>
      </div>
    </div>
    <div class="container">
      <div class="content">
        <div class="sliderr row">
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option" data-aos="zoom-in">
              <img src="assets/img/upay.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option" data-aos="zoom-in" data-aos-delay="100">
              <img src="assets/img/nagad.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option" data-aos="zoom-in" data-aos-delay="200">
              <img src="assets/img/rocket.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option" data-aos="zoom-in" data-aos-delay="300">
              <img src="assets/img/bkash.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option" data-aos="zoom-in" data-aos-delay="400">
              <img src="assets/img/phonepe.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option" data-aos="zoom-in" data-aos-delay="500">
              <img src="assets/img/gpay.png" alt="" />
            </div>
          </div>

          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option" data-aos="zoom-in" data-aos-delay="600">
              <img src="assets/img/paytm.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option" data-aos="zoom-in" data-aos-delay="700">
              <img src="assets/img/bharat-pay.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="Our_Approach" class="globel-coverage">
    <div class="container">
      <div class="head-content">
        <h2>Our Global Reach</h2>
      </div>
      <div class="all_countries">
        <div class="countries">
          <h3>Current Operating Countries</h3>
          <div class="country" data-aos="zoom-out">
            <img src="assets/home/images/Bangladesh.png" alt="" />
            <h4>Bangladesh</h4>
          </div>
          <div class="country" data-aos="zoom-out" data-aos-delay="100">
            <img src="assets/home/images/India.png" alt="" />
            <h4>India</h4>
          </div>
          <div class="country" data-aos="zoom-out" data-aos-delay="200">
            <img src="assets/home/images/Pakistan.png" alt="" />
            <h4>Pakistan</h4>
          </div>
          <div class="country" data-aos="zoom-out" data-aos-delay="300">
            <img src="assets/home/images/Egypt.png" alt="" />
            <h4>Egypt</h4>
          </div>
        </div>
        <div class="countries">
          <h3>Upcoming Countries</h3>
          <div class="country" data-aos="zoom-out">
            <img src="assets/home/images/Nepal.png" alt="" />
            <h4>Nepal</h4>
          </div>
          <div class="country" data-aos="zoom-out" data-aos-delay="100">
            <img src="assets/home/images/Sri-Lanka.png" alt="" />
            <h4>Sri Lanka</h4>
          </div>
          <div class="country" data-aos="zoom-out" data-aos-delay="200">
            <img src="assets/home/images/Vietnam.png" alt="" />
            <h4>Vietnam</h4>
          </div>
          <div class="country" data-aos="zoom-out" data-aos-delay="300">
            <img src="assets/home/images/Indonesia.png" alt="" />
            <h4>Indonesia</h4>
          </div>
        </div>
        <div class="countries">
          <h3>Upon Request Countries</h3>
          <div class="country" data-aos="zoom-out">
            <img src="assets/home/images/African-Countries.png" alt="" />
            <h4>African Countries</h4>
          </div>
          <div class="country" data-aos="zoom-out" data-aos-delay="100">
            <img src="assets/home/images/Latin-American-Countries.png" alt="" />
            <h4>Latin American Countries</h4>
          </div>
          <div class="country" data-aos="zoom-out" data-aos-delay="200">
            <img src="assets/home/images/Others.png" alt="" />
            <h4>{{ "others" | translate }}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="form-section" id="become_a_partner">
    <div class="container-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-6" data-aos="fade-right">
            <div class="contact-info">
              <h2>Connect With Us:</h2>
              <p>
                Self are here, looking forward for a possibility to explain you
                how Pay2Local can improve your business with the help of
                improved payments services. If there is any question one want an
                answer to, or any assistance that one may need from the
                organization to be a member or otherwise, we are here. Please
                come to us today, we would be delighted to listen to you on how
                you would wish to be assisted in achieving your goals. Contact
                us through the contact form or email or via social networks and
                we will open the gate to harmonic payment methods.
              </p>
              <ul>
                <li>
                  <p>
                    <a href="mailto:contact@pay2local.com"
                      ><i class="fa-regular fa-envelope"></i>
                      <h3>E-mail</h3></a
                    >
                  </p>
                </li>
                <li>
                  <p>
                    <a href="mailto:contact@pay2local.com"
                      ><i class="fa-brands fa-telegram"></i>
                      <h3>Telegram</h3></a
                    >
                  </p>
                </li>
                <li>
                  <p>
                    <a href="mailto:contact@pay2local.com"
                      ><i class="fa-brands fa-skype"></i>
                      <h3>Skype</h3></a
                    >
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6" data-aos="fade-left">
            <div class="form-wrapper">
              <form action="">
                <div class="parent-field">
                  <div class="form-field">
                    <label for="">{{ "full_name" | translate }}</label>
                    <input type="text" />
                  </div>
                  <div class="form-field">
                    <label for="">{{ "email" | translate }}</label>
                    <input type="text" />
                  </div>
                </div>
                <div class="form-field">
                  <label for="">{{ "phone_number" | translate }}</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label for="">{{ "country" | translate }}</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label for="">{{ "message" | translate }}</label>
                  <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
                <div class="form-btn-field">
                  <button class="btn">{{ "submit" | translate }}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <div class="footer-logo">
            <img class="logo-img" src="assets/img/logo.png" alt="" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="social">
            <a href=""><i class="fa-brands fa-facebook-f"></i></a>
            <a href=""><i class="fa-brands fa-instagram"></i></a>
            <a href=""><i class="fa-brands fa-x-twitter"></i></a>
          </div>
        </div>
        <!-- <div class="col-md-2">
					<div class="footer-menu-item">
						<a routerLink="/documentation"
						routerLinkActive="active">Developers</a>
					</div>
				</div>
				<div class="col-md-2">
					<div class="footer-menu-item"><a href="">Policy</a></div>
				</div>
				<div class="col-md-2">
					<div class="footer-menu-item">
						<a href="">Term & Condition</a>
					</div>
				</div> -->
      </div>
      <div class="copyright">
        <p>Copyright © 2024 Pay2Local. All rights reserved.</p>
      </div>
    </div>
  </footer>
</div>
<script
  type="text/javascript"
  src="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick.min.js"
></script>
