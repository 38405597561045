import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageMerchantService {

  apiUrl:string = environment.backendHost;
  projectName: string = environment.project;
  constructor(private http :HttpClient) {}

  sendUserProfile(data:any){
    localStorage.setItem(
        this.projectName+'merchantUserProfile',
        JSON.stringify(data)
    );
  }
  getUserProfile(){
   let userProfile:any = localStorage.getItem(this.projectName+'merchantUserProfile') || null;
   userProfile = JSON.parse(userProfile);
   return userProfile;
  }
  removeUerProfile(){
    localStorage.removeItem(this.projectName+'merchantUserProfile');
  }
  sendpinCode(data:any){
    localStorage.setItem(this.projectName+'merchantPinCode',JSON.stringify(data));
  }
  getPinCode(){
    let userPincode:any = localStorage.getItem(this.projectName+'merchantPinCode')  || null;
    userPincode = JSON.parse(userPincode);
    return userPincode;
  }
  removePindCode(){
    localStorage.removeItem(this.projectName+'merchantPinCode');
  }
  sendAdminPermisson(data:any){
    localStorage.setItem(this.projectName+'merchantPermission',JSON.stringify(data));
  }
  getAdminPermisson(){
    let adminPermisson:any = localStorage.getItem(this.projectName+'merchantPermission')  || null;
    adminPermisson = JSON.parse(adminPermisson);
    return adminPermisson;
  
  }
  removeAdminPermisson(){
    localStorage.removeItem(this.projectName+'merchantPermission');
  }
}
